import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'Comedy'}
              title={'Charlotte Dobre'}
              image={'/blogs/youtube-millionaires-charlotte-dobre-1920x1131.jpg'}
              alt={''}
            >
              <div className={styles.content}>
                <p className={styles.excerpt}>
                Charlotte Dobre is a Canadian YouTuber who has gained a following
                 for her engaging react content on a wide range of topics.
                  With over 1,3 million subscribers and counting, her channel
                   offers a variety of videos containing hilarious reaction to
                    TikTok memes, viral videos, funny reddit stories and pop culture.
                </p>
              </div>
              <div className={styles.imagesContainer}>
                  <img src={'/blogs/charlote-thumbnail.png'} alt={'picture'} />
              </div>
              <div className={styles.content}>
                <h2 className={styles.blogSubHeader}>ENG</h2>
                <p className={styles.blogParagraph}>
                  In her current content she is most known for react videos for
                  entitled people, people being petty and bridezillas that are shared publically world wide.
                  Being wildly popular and creating even more react videos
                  her subscriber count grows as the topics reacted on in her videos are
                  relatable topics from everyone's lives.
                </p>
                <p className={styles.blogParagraph}>
                  As for artistic side, her videos have multiple editors that do funny edits to the videos
                  as well as Charlotte's reactions them selves keeping the videos higly engaging and charming.
                  Of course her set contains several references to the work 'petty' as it is one of the main
                  reasons so many people can relate to her videos.
                </p>
                <p className={styles.blogParagraph}>
                  See her content on YouTube: <a href="https://www.youtube.com/@CharlotteDobre">Charlotte Dobre</a>
                </p>
              </div>
              <div className={styles.content}>
                <br></br>
                <h2 className={styles.blogSubHeader}>LV</h2>
                <p className={styles.blogParagraph}>
                Šarlote Dobre ir kanādiešu YouTube lietotāja, kas ir ieguvusi sekotāju skaitu
                  par viņas saistošo reakcijas saturu par dažādām tēmām.
                   Viņas kanālam ir vairāk nekā 1,3 miljoni abonentu
                    piedāvā dažādus videoklipus, kuros ir jautra reakcija uz
                     TikTok mēmes, vīrusu videoklipi, smieklīgi reddit stāsti un popkultūra.
                </p>
                <p className={styles.blogParagraph}>
                Savā pašreizējā saturā viņa ir visvairāk pazīstama ar reaģēšanas videoklipiem
                   tiesīgi cilvēki, cilvēki, kas ir sīki, un bridezillas, kas tiek publiski izplatīti visā pasaulē.
                   Būt ļoti populāram un izveidot vēl vairāk reaģējošu videoklipu
                   viņas abonentu skaits pieaug, jo viņas videoklipos tiek reaģētas uz tēmām
                   sakarīgas tēmas no ikviena dzīves.
                </p>
                <p className={styles.blogParagraph}>
                Kas attiecas uz māksliniecisko pusi, viņas videoklipiem ir vairāki redaktori, kas veic smieklīgas videoklipu rediģēšanas
                   kā arī pašas Šarlotes reakcijas, saglabājot videoklipus ļoti saistošus un burvīgus.
                   Protams, viņas komplektā ir vairākas atsauces uz darbu 'sīko', jo tas ir viens no galvenajiem
                   iemeslu dēļ tik daudzi cilvēki var būt saistīti ar viņas videoklipiem.
                </p>
                <p className={styles.blogParagraph}>
                Skatiet viņas saturu pakalpojumā YouTube: <a href="https://www.youtube.com/@CharlotteDobre">Charlotte Dobre</a>
                </p>
              </div>
            </Blog>
          </div>
          <div className={styles.footerContainer}>
        <span>Find her content on:</span>
        <div className={styles.socialMediaListContainer}>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'twitterinverse'}></Icon>
          </div>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'facebookinverse'}></Icon>
          </div>
         {/*<div className={styles.socialMediaIconContainer}>
            <Icon symbol={'pinterestinverse'}></Icon>
</div> */}
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'youtube'}></Icon>
          </div>
        </div>
        <Button onClick={() => navigate('/')} level={'secondary'}>
          back to summaries
        </Button>
      </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
